<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form>
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <!-- current password -->
              <v-text-field
                v-model="currentPassword"
                :type="isCurrentPasswordVisible ? 'text' : 'password'"
                :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Current Password"
                outlined
                dense
                @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
              ></v-text-field>

              <!-- new password -->
              <v-text-field
                v-model="newPassword"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="New Password"
                outlined
                dense
                hint="Make sure it's at least 8 characters."
                persistent-hint
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>

              <!-- confirm password -->
              <v-text-field
                v-model="cPassword"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Confirm New Password"
                outlined
                dense
                class="mt-3"
                @click:append="isCPasswordVisible = !isCPasswordVisible"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <!-- divider -->
      <!--      <v-divider></v-divider>-->

      <!--      <div class="pa-3">-->
      <!--        <v-card-title class="flex-nowrap">-->
      <!--          <v-icon class="text&#45;&#45;primary me-3">-->
      <!--            {{ icons.mdiKeyOutline }}-->
      <!--          </v-icon>-->
      <!--          <span class="text-break">Two-factor authentication</span>-->
      <!--        </v-card-title>-->

      <!--        <v-card-text class="two-factor-auth text-center mx-auto">-->
      <!--          <v-avatar-->
      <!--            color="primary"-->
      <!--            class="primary mb-4"-->
      <!--            rounded-->
      <!--          >-->
      <!--            <v-icon-->
      <!--              size="25"-->
      <!--              color="white"-->
      <!--            >-->
      <!--              {{ icons.mdiLockOpenOutline }}-->
      <!--            </v-icon>-->
      <!--          </v-avatar>-->
      <!--          <p class="text-base text&#45;&#45;primary font-weight-semibold">-->
      <!--            Two factor authentication is not enabled yet.-->
      <!--          </p>-->
      <!--          <p class="text-sm text&#45;&#45;primary">-->
      <!--            Two-factor authentication adds an additional layer of-->
      <!--            security to your account by requiring more than just a-->
      <!--            password to log in. Learn more.-->
      <!--          </p>-->
      <!--        </v-card-text>-->

      <!--        &lt;!&ndash; action buttons &ndash;&gt;-->
      <!--        <v-card-text>-->
      <!--          <v-btn-->
      <!--            color="primary"-->
      <!--            class="me-3 mt-3"-->
      <!--          >-->
      <!--            Save changes-->
      <!--          </v-btn>-->
      <!--          <v-btn-->
      <!--            color="secondary"-->
      <!--            outlined-->
      <!--            class="mt-3"-->
      <!--          >-->
      <!--            Cancel-->
      <!--          </v-btn>-->
      <!--        </v-card-text>-->
      <!--      </div>-->
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const currentPassword = ref('12345678')
    const newPassword = ref('87654321')
    const cPassword = ref('87654321')

    return {
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isCPasswordVisible,
      newPassword,
      cPassword,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
